export function setClients(state, clients) {
    state.clients = clients;
}

export function setClient(state, client) {
    state.client = client;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
