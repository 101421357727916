import Vue from 'vue'
import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getProducts(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/products?page='+ context.state.pagination.current_page + '&q=' + data.search)

            .then(response => {
                context.commit('setProducts', response.data.data)
                context.commit('setPagination', response.data.meta)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
export function getProductsSearch(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/search/products?page='
            + context.state.pagination.current_page
            + '&q=' + data.search
            + '&cellar=' + data.cellar
            + '&active=' + data.active)
            .then(response => {
                context.commit('setProducts', response.data.data)
                context.commit('setPagination', response.data.meta)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function getProductsSearchCellars(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/search/products-cellars?cellar=' + data.bodega)
            .then(response => {
                context.commit('setProducts', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
export function getProduct(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/products/'+data)
            .then(response => {
                context.commit('setProduct', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}


export function storeProducts(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/products',data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function updateProducts(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/products/'+data.id,data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function deleteProducts(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.delete('/api/v1/products/'+data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            //console.log(err);
            reject(err)
        })
    })
}
