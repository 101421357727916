import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../modules/auth';
import roles from '../modules/roles';
import users from '../modules/users';
import clients from '../modules/clients';
import businesses from '../modules/businesses';
import inspections from '../modules/inspections';
import emergencies from '../modules/emergencies';
import supplies from '../modules/supplies';
import heas from '../modules/heas';
import machineries from '../modules/machineries';
import crews from '../modules/crews';
import reports from '../modules/reports';
import categories from '../modules/categories';
import sentences from '../modules/sentences'
import products from '../modules/products'
import cellars from '../modules/cellars'
import movements from '../modules/movements'
import revisions from '../modules/revisions'
import mileage from '../modules/mileage'
import machineryCheckQuestions from '../modules/machineryCheckQuestions'
import checkQuestions from '../modules/checkQuestions'
import checkList from '../modules/checkList'
import shiftDeliveries from '../modules/shiftDeliveries'
import prehospitalCare from '../modules/prehospitalCare'
import files from '../modules/files'
import logs from '../modules/logs';

import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  store: window.localStorage,
  modules: [
    'auth',
    'clients',
    'businesses',
    'inspections',
    'reports',
    'emergencies',
    'supplies',
    'heas',
    'machineries',
    'crews',
    'shiftDeliveries',
    'users'
  ]
});
export default new Vuex.Store({
  state: {
    loader: false,
    dialogSuccess: false,
  },
  mutations: {
    loader(state) {
      state.loader = !state.loader
    },
    dSuccess(state) {
      state.dialogSuccess = !state.dialogSuccess
    }
  },
  getters: {},
  actions: {
    loader(context) {
      context.commit('loader')
    },
    dSuccess(context) {
      context.commit('dSuccess')
    }
  },
  modules: {
    auth,
    roles,
    users,
    clients,
    businesses,
    inspections,
    emergencies,
    supplies,
    heas,
    machineries,
    crews,
    reports,
    categories,
    sentences,
    logs,
    products,
    cellars,
    movements,
    revisions,
    mileage,
    machineryCheckQuestions,
    checkQuestions,
    checkList,
    shiftDeliveries,
    prehospitalCare,
    files
  },
  plugins: [vuexLocal.plugin]
})
