import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getCheckList(context) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/check-lists')
      .then(response => {
        context.commit('SET_CHECK_LISTS', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function showCheckList(context, id) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/check-lists/' + id)
      .then(response => {
        context.commit('SET_CHECK_LIST', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}


export function storeCheckList(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.post('/api/v1/check-lists', data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function updateCheckList(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/check-lists/' + data.id, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function deleteCheckList(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.delete('/api/v1/check-lists/' + data)
      .then(response => {
        resolve(response)
      }).catch(err => {
      reject(err)
    })
  })
}

export function getAllCheckLists(context) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/check-lists/all')
      .then(response => {
        context.commit('SET_CHECK_LISTS', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function storeShiftDeliveryCheckLists(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.post(`/api/v1/shift-deliveries/${data.id}/check-lists`, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function updateShiftDeliveryCheckLists(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put(`/api/v1/shift-deliveries/check-lists/${data.id}`, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}
