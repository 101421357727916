import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es'
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);
const opts = {
    theme: {
        themes: {
            dark: {
                accent: '#82B1FF',
            },
        }
    },
    lang: {
        locales: { es },
        current: 'es',
    },
}
export default new Vuetify(opts)
