import axios from 'axios'
//llamado al login
export function signIn(context, user) {
    return  new Promise((resolve, reject) => {
        axios.post('/api/login',{
            identificacion:user.identificacion,
            password:user.password
        }, {errorHandle: false}).then(response=>{
            const token  = response.data.access_token
            context.commit('setToken',token)
            context.commit('setUser',response.data.user)
            context.commit('setAbilities',response.data.abilities)
            context.commit('setTokenType',response.data.token_type)
            // console.log(response)
            resolve(response)
        }).catch(e=>{
            if (e.response.status == 401){

            }else if (e.response.status == 422){

            } else{
                console.log('default '+e)
            }
            // console.log(e)
            // console.log(e.data.status)
            // context.commit( 'authError',e.message)
            reject(e)
        })
    })
}


export function logout(context) {
    axios.defaults.headers.common['Authorization'] = context.state.token_type+' ' + context.state.token
    return  new Promise((resolve, reject) => {
        axios.post('/api/v1/logout').then(response=>{
            localStorage.removeItem('vuex');
            context.commit('destroyToken')
            resolve(response)
        }).catch(e=>{
            // console.log(e)
            localStorage.removeItem('vuex');
            context.commit('destroyToken')
            context.commit('authError',e.message)
            reject(e)
        })
    })
}

export function updatePassword(context,user) {
    axios.defaults.headers.common['Authorization'] = context.state.token_type+' ' + context.state.token
    return  new Promise((resolve, reject) => {
        axios.put('/api/v1/profile',user).then(response=>{
            const token  = response.data.access_token
            context.commit('setToken',token)
            context.commit('setUser',response.data.user)
            context.commit('setAbilities',response.data.abilities)
            context.commit('setTokenType',response.data.token_type)
            resolve(response)
        }).catch(e=>{
            reject(e)
        })
    })
}

export function resetPassword(context,user) {
    console.log(user);
    axios.defaults.headers.common['Authorization'] = context.state.token_type+' ' + context.state.token
    return  new Promise((resolve, reject) => {
        axios.put('/api/v1/reset-password',user).then(response=>{
            const token  = response.data.access_token
            context.commit('setToken',token)
            context.commit('setUser',response.data.user)
            context.commit('setAbilities',response.data.abilities)
            context.commit('setTokenType',response.data.token_type)
            resolve(response)
        }).catch(e=>{
            reject(e)
        })
    })
}
