export function setMovements(state, movements) {
    state.movements = movements;
}

export function setMovement(state, movement) {
    state.movement = movement;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
