export function setReports(state, reports) {
    state.reports = reports;
}

export function setReport(state, report) {
    state.report = report;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
