export function SET_FILES(state, files) {
    state.files = files;
}

export function SET_FILES_ARCHIVES(state, files) {
    state.files_archives = files;
}

export function SET_USER_AUTH(state, files) {
    state.auth_user_files = files;
}
export function SET_FILES_PAGINATION(state, pagination) {
    state.pagination = pagination;
}

export function SET_RESET_PAGINATION(state, pagination) {
    state.pagination.current_page = pagination;
}
