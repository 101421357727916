import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getMachineriesMileages(context, id) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/machinery-mileages/' + id, {
            params: {
                page: context.state.pagination.current_page
            }
        })
            .then(response => {
                context.commit('SET_MILEAGES', response.data.data)
                context.commit('SET_MILEAGES_PAGINATION', response.data.meta)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function getMachineryMileage(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/machinery-mileages/' + data)
            .then(response => {
                context.commit('SET_MILEAGE', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function firstMachineryMileage(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/machinery-mileages/first/' + data)
            .then(response => {
                context.commit('SET_MILEAGE_FIRSTS', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}


export function storeMachineriesMileages(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/machinery-mileages', data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
