import Vue from 'vue'
import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getCellars(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/cellars')
            .then(response => {
                context.commit('setCellars', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function getCellar(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/cellars/'+data)
            .then(response => {
                context.commit('setCellar', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}


export function storeCellars(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/cellars',data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function updateCellars(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/cellars/'+data.id,data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function deleteCellars(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.delete('/api/v1/cellars/'+data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            //console.log(err);
            reject(err)
        })
    })
}
