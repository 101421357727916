export default {
  files: [],
  files_archives: [],
  file: null,
  auth_user_files: [],
  pagination: {
    total: 1,
    current_page: 1,
  },
}
