export function setInspections(state, inspections) {
    state.inspections = inspections;
}

export function setInspection(state, inspection) {
    state.inspection = inspection;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
