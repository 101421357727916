import Vue from 'vue'
import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'));

export function getHEAS(context) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/heas')
            .then(response => {
                context.commit('setHEAS', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
export function getHEA(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/heas/'+data)
            .then(response => {
                context.commit('setHEA', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}


export function storeHEAS(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/heas',data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function updateHEAS(context,data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/heas/'+data.id,data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function deleteHEAS(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.delete('/api/v1/heas/'+data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            //console.log(err);
            reject(err)
        })
    })
}
