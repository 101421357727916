export default {
    clients:[],
    client:{
        relaciones:{
            empresas:[],
            establecimientos:{
                data:{}
            }
        }
    },
    pagination:{
        total:0,
        current_page:0,
    },
}
