export default {
    revisions:[],
    revision:{
        relaciones:{
            usuario:{},
            productos:{},
            bodega:{},
        }
    },
    pagination:{
        total:0,
        current_page:0,
    },
}
