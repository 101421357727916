import Vue from 'vue'
import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getRoles(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/roles?page=' + context.state.pagination.current_page+ '&search=' + data.search)
            .then(response => {
                context.commit('setRoles', response.data.data)
                context.commit('setPagination', response.data.meta)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
export function getRolesAll(context) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/allroles')
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
export function getRol(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/roles/'+data)
            .then(response => {
                context.commit('setRol', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function getAbilities(context) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/abilities')
            .then(response => {
                context.commit('setAbilities', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function storeRoles(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/roles', data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            //console.log(err);
            reject(err)
        })
    })
}

export function updateRoles(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/roles/'+data.id, data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            //console.log(err);
            reject(err)
        })
    })
}


