import Vue from 'vue'
import axios from 'axios'
import {getAllBetween} from "../inspections/actions";

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getReports(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/reports?page=' + context.state.pagination.current_page + '&q=' + data.search)
            .then(response => {
                context.commit('setReports', response.data.data)
                context.commit('setPagination', response.data.meta)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function getReport(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/reports/' + data)
            .then(response => {
                context.commit('setReport', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}


export function storeReports(context, data) {

    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/reports', data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function updateReports(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/reports/' + data.id, data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function deleteReports(context, data) {

    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.delete('/api/v1/reports/' + data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            //console.log(err);
            reject(err)
        })
    })
}

export function getAllBetweenReports(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/search/reports/between', {
            params: {
                start_date: data.fecha_inicio,
                end_date: data.fecha_fin,
                start_time: data.hora_inicio,
                end_time: data.hora_fin,
                edit: data.edit
            }
        }).then(response => {
            resolve(response)
        }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}
