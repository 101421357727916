export function SET_SHIFT_DELIVERIES(state, shift_deliveries) {
    state.shift_deliveries = shift_deliveries;
}

export function SET_SHIFT_DELIVERY(state, shift_delivery) {
    state.shift_delivery = shift_delivery;
}

export function SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
}

export function SET_RESET_PAGINATION(state, pagination) {
    state.pagination.current_page = pagination;
}

