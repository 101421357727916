export function setRevisions(state, revisions) {
    state.revisions = revisions;
}

export function setRevision(state, revision) {
    state.revision =revision;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
