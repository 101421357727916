<template>
    <v-list dense>
        <template v-for="item in items">
            <v-row
                v-if="item.heading"
                :key="item.heading"
                align="center"
            >
                <v-col cols="6">
                    <v-subheader v-if="item.heading">
                        {{ item.heading }}
                    </v-subheader>
                </v-col>
            </v-row>
            <v-list-group
                v-else-if="item.children && auth.permission(item.permission)"
                :key="item.text"
                v-model="item.model"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon=""
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item
                    class="pl-12"
                    v-for="(child, i) in item.children"
                    :key="i"
                    v-if="auth.permission(child.permission)"
                    :to="child.link"
                    exact-active-class="blue accent-4 white--text "
                    link>
                    <v-list-item-action v-if="child.icon">
                        <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ child.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item
                v-else-if="auth.permission(item.permission)"
                :key="item.title"
                :to="item.link"
                exact-active-class="blue accent-4 white--text"
                link
            >
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>
<script>
export default {
    data: () => ({
        items: [
            {title: 'Tablero', icon: 'mdi-view-dashboard', link: '/', permission: 'home'},
            {title: 'Clientes', icon: 'mdi-account-tie', link: '/clients', permission: 'clients-index'},
            {title: 'Empresas', icon: 'mdi-domain', link: '/business', permission: 'business-index'},
            {title: 'Inspecciones', icon: 'mdi-clipboard-text', link: '/inspections', permission: 'inspections-index'},
            {title: 'Reportes', icon: 'mdi-file-document-outline', link: '/reports', permission: 'reports-index'},
            {
                title: 'Inventario',
                icon: 'mdi-chevron-up',
                'icon-alt': 'mdi-chevron-down',
                model: false,
                link: '',
                permission: 'inventory',
                children: [
                    {
                        title: 'Movimientos',
                        icon: 'mdi-swap-horizontal',
                        link: '/movements',
                        permission: 'movements-index'
                    },
                    {
                        title: 'Productos',
                        icon: 'mdi-package-variant-closed',
                        link: '/products',
                        permission: 'products-index'
                    },
                    {title: 'Bodegas', icon: 'mdi-archive', link: '/cellars', permission: 'cellars-index'},
                    {
                        title: 'Reportes de bodegas',
                        icon: 'mdi-format-list-checkbox',
                        link: '/revisions',
                        permission: 'revisions-index'
                    },
                    {title: 'Categorias', icon: 'mdi-shape', link: '/categories', permission: 'categories-index'},
                ]
            },
            {title: 'Maquinaria', icon: 'mdi-fire-truck', link: '/machineries', permission: 'machineries-index'},
            {title: 'Entrega de turnos', icon: 'mdi-swap-horizontal', link: '/shift-deliveries', permission: 'shift-deliveries-index'},
            {title: 'Administrar perfiles', icon: 'mdi-account-group-outline', link: '/profiles', permission: 'admin-profiles-index'},
            {title: 'Archivos', icon: 'mdi-file-document-multiple-outline', link: '/files', permission: 'files-index'},
            {
                title: 'Panel de control',
                icon: 'mdi-chevron-up',
                'icon-alt': 'mdi-chevron-down',
                model: false,
                link: '',
                permission: 'panel-admin',
                children: [
                    {title: 'Usuarios', icon: 'mdi-account-group', link: '/users', permission: 'users-index'},
                    {title: 'Roles', icon: 'mdi-format-list-checks', link: '/roles', permission: 'roles-index'},
                    {
                        title: 'Clases de emergencia',
                        icon: 'mdi-bell-alert',
                        link: '/emergencies',
                        permission: 'emergencies-index'
                    },
                    {title: 'Insumos', icon: 'mdi-briefcase-outline', link: '/supplies', permission: 'supplies-index'},
                    {title: 'HEA´S', icon: 'mdi-hard-hat', link: '/heas', permission: 'heas-index'},
                    {title: 'Tripulación', icon: 'mdi-human', link: '/crews', permission: 'crews-index'},
                    {title: 'Frases', icon: 'mdi-format-textbox', link: '/sentences', permission: 'sentences-index'},
                    {
                        title: 'Preguntas maquinaria',
                        icon: 'mdi-chat-question',
                        link: '/machinery-check-questions',
                        permission: 'machinery-check-questions-index'
                    },
                    {
                        title: 'Preguntas chequeo',
                        icon: 'mdi-chat-question-outline',
                        link: '/check-questions',
                        permission: 'check-questions-index'
                    },
                    {
                        title: 'Listas de chequeo',
                        icon: 'mdi-clipboard-check-outline',
                        link: '/check-lists',
                        permission: 'check-lists-index'
                    },
                    {title: 'Logs', icon: 'mdi-format-list-numbered-rtl', link: '/logs', permission: 'logs'},
                ]
            },
        ],
    })
}
</script>
