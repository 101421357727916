export function setUsers(state, users) {
    state.users = users;
}

export function setUser(state, user) {
    state.user = user;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}


export function SET_ALL_USERS(state, usersAll) {
    state.usersAll = usersAll;
}
