import Vue from 'vue'
import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getUsers(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/users?page=' + context.state.pagination.current_page + '&search=' + data.search)
      .then(response => {
        context.commit('setUsers', response.data.data)
        context.commit('setPagination', response.data.meta)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function getUser(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/users/' + data)
      .then(response => {
        context.commit('setUser', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}


export function storeUsers(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.post('/api/v1/users', data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function updateUsers(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/users/' + data.id, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function deleteUser(context, data) {

  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.delete('/api/v1/users/' + data)
      .then(response => {
        resolve(response)
      }).catch(err => {
      reject(err)
    })
  })
}

export function getAllUsers(context) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/users/all')
      .then(response => {
        context.commit('SET_ALL_USERS', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function signatureUser(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.post('/api/v1/users/signatures', data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function profileUserUpdate(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.post('/api/v1/users/profiles', data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}
