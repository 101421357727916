export default {
    user: {
        relaciones: {
            roles: [{title: ''}],
            habilidades: [{title: ''}]
        }
    },
    users: [],
    usersAll: [],
    pagination: {
        total: 0,
        current_page: 0,
    },
}
