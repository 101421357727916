export default {
    businesses:[],
    business:{
        relaciones:{
            cliente:{}
        }
    },
    pagination:{
        total:0,
        current_page:0,
    },
}
