import Vue from 'vue';
import {
  required,
  email,
  min,
  confirmed,
  numeric,
  required_if,
  max_value,
  min_value,
  max,
  mimes,
  size
}  from 'vee-validate/dist/rules';;
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {localize} from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';

extend('email', email);
extend('required', required);
extend('min', min);
extend('max', max);
extend('max_value', max_value);
extend('min_value', min_value)
extend('confirmed', confirmed);
extend('numeric', numeric);
extend('required_if', required_if);
extend('mimes', mimes);
extend('size', size);

extend('decimal', {
  validate: (value, {decimals = '*', separator = '.'} = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return {
      valid: regex.test(value),
    };
  },
  message: 'Solo se aceptan números y decimales'
})

localize('es', es);
Vue.component('ValidationProvider', ValidationProvider).default;
Vue.component('ValidationObserver', ValidationObserver).default;



