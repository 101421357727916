export function setBusinesess(state, businesses) {
    state.businesses = businesses;
}

export function setBusiness(state, business) {
    state.business = business;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
