import axios from 'axios'

function errorResponseHandler(error) {
  // check for errorHandle config
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error);
  }
  // if has response show the error
  if (error.response) {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem('vuex')
        window.location.href = "/login";
        break;
      // case 403:
      //   router.push({name: '403'});
      //   return Promise.reject(error);
      //   break;
      // case 404:
      //   router.push({name: '404'});
      //   return Promise.reject(error);
      //   break;
      // case 500:
      //   router.push({name: '500'});
      //   return Promise.reject(error);
      //   break;
      default:
        return Promise.reject(error);
    }
  }
}

// apply interceptor on response
axios.interceptors.response.use(
  response => response,
  errorResponseHandler
);

export default errorResponseHandler;
