export default {
    inspections:[],
    inspection:{
        material_combustible:{},
        relaciones:{
            empresa:{
                relaciones:{
                    cliente:{} ,
                }
            },
            usuaria_creador:{},
            inspecciones:{},
            reinspeccion:{}


        }
    },
    pagination:{
        total:0,
        current_page:0,
    },
}
