<template>
    <v-app id="sandbox" v-if="isLogged">
        <v-navigation-drawer
                v-model="primaryDrawer.model"
                :clipped="primaryDrawer.clipped"
                :permanent="primaryDrawer.type === 'permanent'"
                :temporary="primaryDrawer.type === 'temporary'"
                app
                overflow>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        Menú de navegación
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <nav-component></nav-component>
        </v-navigation-drawer>

        <v-app-bar
                :clipped-left="primaryDrawer.clipped"
                color="blue darken-3"
                app>
            <v-app-bar-nav-icon
                    dark
                    v-if="primaryDrawer.type !== 'permanent'"
                    @click.stop="primaryDrawer.model = !primaryDrawer.model"
            />

            <v-toolbar-title class="flex">
                <v-img src="/img/logo.png" height="40" width="40"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                            :color="onLine ? 'success': 'red'"
                            small
                            v-bind="attrs"
                            v-on="on">
                        {{ onLine ? 'mdi-led-off' : "mdi-led-off" }}
                    </v-icon>
                </template>
                <span>  {{ onLine ? 'Conectado a internet' : "NO hay conexión a internet" }} </span>
            </v-tooltip>
            <v-btn
                    icon
                    dark
                    @click="dark = !dark"
            >
                <v-icon>
                    mdi-theme-light-dark
                </v-icon>
            </v-btn>
            <v-menu
                    bottom
                    min-width="200px"
                    rounded
                    offset-y
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                            icon
                            x-large
                            v-on="on"
                    >
                        <v-avatar
                                v-if="user.profile && user.profile.photo"
                                size="36"
                        >
                            <img
                                    :src="`${user.profile.photoUrl}`"
                                    :alt="user.name"
                            >
                        </v-avatar>
                        <v-avatar
                                v-else
                                color="grey lighten-2"
                                class="grey--text"
                                dark
                                size="36"
                        >
                            <span>{{ user.name[0] }}{{ user.lastname[0] }}</span>
                        </v-avatar>
                    </v-btn>
                </template>
                <v-card>
                    <v-list-item-content class="justify-center">
                        <div class="mx-auto text-center">
                            <h5>{{ user.name }} {{ user.lastname }}</h5>
                            <p class="text-caption mt-1">
                                {{ user.profile ? user.profile.profession : '' }}
                            </p>
                            <v-divider class="my-3"></v-divider>
                            <v-btn
                                    depressed
                                    rounded
                                    text
                                    :to="{name:'perfil'}"
                            >
                                Perfil
                            </v-btn>
                            <v-divider class="my-3"></v-divider>
                            <v-btn
                                    depressed
                                    rounded
                                    text
                                    @click="_logout()"
                            >
                                Desconectarse
                            </v-btn>
                        </div>
                    </v-list-item-content>
                </v-card>
            </v-menu>
        </v-app-bar>

        <v-main>
            <v-container fluid transition="scale-transition">
                <router-view />
            </v-container>
        </v-main>

        <v-footer
                :inset="true">
            <v-spacer></v-spacer>
            <span class="px-4">&copy; {{ new Date().getFullYear() }}
                <a target="_black" class="underlinenone"
                   href="https://bomberosyarumal.com/">Bomberos de Yarumal</a></span>
        </v-footer>
        <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar
                :value="dialogSuccess"
                @input="_dialogSuccess"
                :multi-line="mode === 'multi-line'"
        >
            Operación exitosa
            <v-btn
                    color="success"
                    text
                    @click="_dialogSuccess()">
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-app>
    <v-app id="sandbox" v-else>
        <router-view></router-view>
        <v-overlay :value="loader" z-index="999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import navComponent from '@/components/listNavigation'

export default {
    components: {
        navComponent,
    },
    watch: {
        dark: {
            handler () {
                localStorage.setItem("dark", JSON.stringify(this.dark))
                this.$vuetify.theme.dark = this.dark
            },
        },
    },
    computed: {
        ...mapState('auth', ['isLogged', 'user']),
        ...mapState(['loader']),
        ...mapState(['dialogSuccess'])


    },
    mounted () {
        this.$vuetify.theme.dark = this.dark
        window.addEventListener('online', this.updateOnlineStatus)
        window.addEventListener('offline', this.updateOnlineStatus)
    },
    beforeDestroy () {
        window.removeEventListener('online', this.updateOnlineStatus)
        window.removeEventListener('offline', this.updateOnlineStatus)
    },
    data: () => ({
        drawers: ['Default (no property)', 'Permanent', 'Temporary'],
        primaryDrawer: {
            model: null,
            type: 'default (no property)',
            clipped: true,
        },
        dark: JSON.parse(localStorage.getItem("dark")) || false,
        mode: '',
        onLine: navigator.onLine,
    }),
    methods: {
        ...mapActions('auth', ['logout']),
        ...mapActions(['dSuccess']),
        _dialogSuccess () {
            this.dSuccess()
        },
        _logout () {
            this.logout().then(response => {
                // console.log('cerrar sesión')
                this.$router.push({ name: 'login' })
            }).catch(e => {
                // console.log('fallo cerrar sesion')
                this.$router.push({ name: 'login' })
            })
        },
        updateOnlineStatus (e) {
            const {
                type
            } = e
            this.onLine = type === 'online'
        },
    }
}
</script>
