export default {
    movements:[],
    movement:{
        relaciones:{
            usuario:{},
            producto:{},
            bodega:{},
        }
    },
    pagination:{
        total:0,
        current_page:0,
    },
}
