import axios from 'axios'
export function getProfileFiles (context, data) {
    return new Promise((resolve, reject) => {
        axios.get('/api/v1/profile-files', {
            params: {
                page: context.state.pagination.current_page,
                q: data.search
            }
        }).then(response => {
            context.commit('SET_FILES', response.data.data)
            context.commit('SET_FILES_PAGINATION', response.data.meta)
            resolve(response)
        }).catch(e => {
            console.log(e.response)
            reject(e)
        })
    })
}

export function getFilesUser (context) {
    return new Promise((resolve, reject) => {
        axios.get('/api/v1/my-files')
            .then(response => {
                context.commit('SET_USER_AUTH', response.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response)
            reject(e)
        })
    })
}

export function storeFiles (context, data) {
    return new Promise((resolve, reject) => {
        axios.post('/api/v1/upload-files', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response)
            reject(e)
        })
    })
}

export function deleteFile (context, id) {
    return new Promise((resolve, reject) => {
        axios.delete('/api/v1/delete-files/' + id)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e)
            console.log(e.response)
            reject(e)
        })
    })
}

export function getFiles (context) {
    return new Promise((resolve, reject) => {
        axios.get('/api/v1/files').then(response => {
            context.commit('SET_FILES_ARCHIVES', response.data)
            resolve(response)
        }).catch(e => {
            console.log(e.response)
            reject(e)
        })
    })
}

export function updateOrder (context, data) {
    return new Promise((resolve, reject) => {
        axios.put('/api/v1/files/orders',data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e)
            console.log(e.response)
            reject(e)
        })
    })
}

