export default [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "view-home" */ '@/views/home.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/403',
        name: '403',
        component: () => import(/* webpackChunkName: "view-403" */ '@/views/403.vue'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "view-404" */ '@/views/404.vue'),
    },
    {
        path: '/500',
        name: '500',
        component: () => import(/* webpackChunkName: "view-500" */ '@/views/500.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "view-login" */ '@/views/auth/login.vue'),
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/perfil',
        name: 'perfil',
        component: () => import(/* webpackChunkName: "view-perfil" */ '@/views/auth/profile.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/perfil/signature',
        name: 'perfil-signature',
        component: () => import(/* webpackChunkName: "view-profile-signature" */ '@/views/auth/profileSignature.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reset',
        name: 'reset',
        component: () => import(/* webpackChunkName: "view-reset" */ '@/views/resetPassword.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    // roles
    {
        path: '/roles',
        name: 'roles-index',
        component: () => import(/* webpackChunkName: "roles-index" */ '@/views/roles/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'roles-create',
        component: () => import(/* webpackChunkName: "roles-create" */ '@/views/roles/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'roles-update',
        component: () => import(/* webpackChunkName: "roles-update" */ '@/views/roles/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //usuario
    {
        path: '/users',
        name: 'users-index',
        component: () => import(/* webpackChunkName: "users-index" */ '@/views/users/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'users-create',
        component: () => import(/* webpackChunkName: "users-create" */ '@/views/users/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/:id',
        name: 'users-show',
        component: () => import(/* webpackChunkName: "users-show" */ '@/views/users/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/:id/edit',
        name: 'users-update',
        component: () => import(/* webpackChunkName: "users-update" */ '@/views/users/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //clientes
    {
        path: '/clients',
        name: 'clients-index',
        component: () => import(/* webpackChunkName: "clients-index" */ '@/views/clients/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/clients/create',
        name: 'clients-create',
        component: () => import(/* webpackChunkName: "clients-create" */ '@/views/clients/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/clients/:id',
        name: 'clients-show',
        component: () => import(/* webpackChunkName: "clients-show" */ '@/views/clients/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/clients/:id/edit',
        name: 'clients-update',
        component: () => import(/* webpackChunkName: "clients-update" */ '@/views/clients/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //establecimientos
    {
        path: '/business',
        name: 'business-index',
        component: () => import(/* webpackChunkName: "business-index" */ '@/views/businesses/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/business/create',
        name: 'business-create',
        component: () => import(/* webpackChunkName: "business-create" */ '@/views/businesses/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/business/:id',
        name: 'business-show',
        component: () => import(/* webpackChunkName: "business-show" */ '@/views/businesses/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/business/:id/edit',
        name: 'business-update',
        component: () => import(/* webpackChunkName: "business-update" */ '@/views/businesses/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //inspecciones
    {
        path: '/inspections',
        name: 'inspections-index',
        component: () => import(/* webpackChunkName: "inspections-index" */ '@/views/inspections/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/inspections/create',
        name: 'inspections-create',
        component: () => import(/* webpackChunkName: "inspections-create" */ '@/views/inspections/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/inspections/:id',
        name: 'inspections-show',
        component: () => import(/* webpackChunkName: "inspections-show" */ '@/views/inspections/show.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/inspections/:id/compliance',
                name: 'inspections-compliance',
                component: () => import(/* webpackChunkName: "inspections-compliance" */ '@/views/inspections/compliance.vue'),
                meta: {
                    requiresAuth: true,
                }
            }
        ]
    },
    {
        path: '/inspections/:id/edit',
        name: 'inspections-update',
        component: () => import(/* webpackChunkName: "inspections-update" */ '@/views/inspections/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/inspections/:id/firm',
        name: 'inspections-firm',
        component: () => import(/* webpackChunkName: "inspections-firm" */ '@/views/inspections/firm.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //clases de emergencia
    {
        path: '/emergencies',
        name: 'emergencies-index',
        component: () => import(/* webpackChunkName: "emergencies-index" */ '@/views/emergencies/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //insumos
    {
        path: '/supplies',
        name: 'supplies-index',
        component: () => import(/* webpackChunkName: "supplies-index" */ '@/views/supplies/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //HEAS
    {
        path: '/heas',
        name: 'heas-index',
        component: () => import(/* webpackChunkName: "heas-index" */ '@/views/heas/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //machineries
    {
        path: '/machineries',
        name: 'machineries-index',
        component: () => import(/* webpackChunkName: "machineries-index" */ '@/views/machineries/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/machineries/create',
        name: 'machineries-create',
        component: () => import(/* webpackChunkName: "machineries-create" */ '@/views/machineries/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/machineries/:id',
        name: 'machineries-show',
        component: () => import(/* webpackChunkName: "machineries-show" */ '@/views/machineries/show.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/machineries/:id/mileage',
                name: 'machineries-mileage',
                component: () => import(/* webpackChunkName: "machineries-compliance" */ '@/views/machineries/mileage.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: '/machineries/:id/documents/create',
                name: 'machineries-documents-create',
                component: () => import(/* webpackChunkName: "machineries-documents-compliance" */ '@/views/machineries/documents/create.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: '/machineries/:id/maintenance/create',
                name: 'machineries-maintenance-create',
                component: () => import(/* webpackChunkName: "machineries-maintenance-compliance" */ '@/views/machineries/maintenance/create.vue'),
                meta: {
                    requiresAuth: true,
                }
            }
        ]
    },
    {
        path: '/machineries/:id/edit',
        name: 'machineries-update',
        component: () => import(/* webpackChunkName: "machineries-update" */ '@/views/machineries/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //crews
    {
        path: '/crews',
        name: 'crews-index',
        component: () => import(/* webpackChunkName: "crews-index" */ '@/views/crews/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //reportes
    {
        path: '/reports',
        name: 'reports-index',
        component: () => import(/* webpackChunkName: "reports-index" */ '@/views/reports/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports/create',
        name: 'reports-create',
        component: () => import(/* webpackChunkName: "reports-create" */ '@/views/reports/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports/:id',
        name: 'reports-show',
        component: () => import(/* webpackChunkName: "reports-show" */ '@/views/reports/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports/:id/edit',
        name: 'reports-update',
        component: () => import(/* webpackChunkName: "reports-update" */ '@/views/reports/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports/:id/prehospital-cares/create',
        name: 'prehospital-care-create',
        component: () => import(/* webpackChunkName: "prehospital-care-create" */ '@/views/prehospitalCare/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports/:id/prehospital-cares/:prehospital/edit',
        name: 'prehospital-care-update',
        component: () => import(/* webpackChunkName: "prehospital-care-edit" */ '@/views/prehospitalCare/edit.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //frases certificados
    {
        path: '/sentences',
        name: 'sentences-index',
        component: () => import(/* webpackChunkName: "sentences-index" */ '@/views/sentences/index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/sentences/create',
                name: 'sentences-create',
                component: () => import(/* webpackChunkName: "sentences-create" */ '@/views/sentences/create.vue'),
                meta: {
                    requiresAuth: true,
                },

            },
            {
                path: '/sentences/:id/edit',
                name: 'sentences-update',
                component: () => import(/* webpackChunkName: "sentences-update" */ '@/views/sentences/update.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },

    //categorias de productos
    {
        path: '/categories',
        name: 'categories-index',
        component: () => import(/* webpackChunkName: "categories-index" */ '@/views/categories/index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/categories/create',
                name: 'categories-create',
                component: () => import(/* webpackChunkName: "categories-create" */ '@/views/categories/create.vue'),
                meta: {
                    requiresAuth: true,
                },

            },
            {
                path: '/categories/:id/edit',
                name: 'categories-update',
                component: () => import(/* webpackChunkName: "categories-update" */ '@/views/categories/update.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },

    //reportes
    {
        path: '/products',
        name: 'products-index',
        component: () => import(/* webpackChunkName: "products-index" */ '@/views/products/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/products/create',
        name: 'products-create',
        component: () => import(/* webpackChunkName: "products-create" */ '@/views/products/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/products/:id',
        name: 'products-show',
        component: () => import(/* webpackChunkName: "products-show" */ '@/views/products/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/products/:id/edit',
        name: 'products-update',
        component: () => import(/* webpackChunkName: "products-update" */ '@/views/products/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //bodegas
    {
        path: '/cellars',
        name: 'cellars-index',
        component: () => import(/* webpackChunkName: "cellars-index" */ '@/views/cellars/index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/cellars/create',
                name: 'cellars-create',
                component: () => import(/* webpackChunkName: "cellars-create" */ '@/views/cellars/create.vue'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/cellars/:id',
        name: 'cellars-show',
        component: () => import(/* webpackChunkName: "cellars-show" */ '@/views/cellars/show.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/cellars/:id/edit',
                name: 'cellars-update',
                component: () => import(/* webpackChunkName: "cellars-update" */ '@/views/cellars/update.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },

    //movements
    {
        path: '/movements',
        name: 'movements-index',
        component: () => import(/* webpackChunkName: "movements-index" */ '@/views/movements/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/movements/create',
        name: 'movements-create',
        component: () => import(/* webpackChunkName: "movements-create" */ '@/views/movements/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/movements/:id',
        name: 'movements-show',
        component: () => import(/* webpackChunkName: "movements-show" */ '@/views/movements/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //movements
    {
        path: '/revisions',
        name: 'revisions-index',
        component: () => import(/* webpackChunkName: "revisions-index" */ '@/views/revisions/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/revisions/create',
        name: 'revisions-create',
        component: () => import(/* webpackChunkName: "revisions-create" */ '@/views/revisions/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/revisions/:id',
        name: 'revisions-show',
        component: () => import(/* webpackChunkName: "revisions-show" */ '@/views/revisions/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //logs
    {
        path: '/logs',
        name: 'logs',
        component: () => import(/* webpackChunkName: "view-logs" */ '@/views/logs/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },


    //machineryCheckQuestions
    {
        path: '/machinery-check-questions',
        name: 'machinery-check-questions-index',
        component: () => import(/* webpackChunkName: "machinery-check-questions-index" */ '@/views/machineryCheckQuestions/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },


    //CheckQuestions
    {
        path: '/check-questions',
        name: 'check-questions-index',
        component: () => import(/* webpackChunkName: "check-questions-index" */ '@/views/checkQuestions/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },

    //CheckQuestions
    {
        path: '/check-lists',
        name: 'check-lists-index',
        component: () => import(/* webpackChunkName: "check-lists-index" */ '@/views/checkList/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },


    //ShiftDeliveries
    {
        path: '/shift-deliveries',
        name: 'shift-deliveries-index',
        component: () => import(/* webpackChunkName: "shift-deliveries-index" */ '@/views/shiftDeliveries/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/shift-deliveries/create',
        name: 'shift-deliveries-create',
        component: () => import(/* webpackChunkName: "shift-deliveries-create" */ '@/views/shiftDeliveries/create.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/shift-deliveries/:id',
        name: 'shift-deliveries-show',
        component: () => import(/* webpackChunkName: "shift-deliveries-show" */ '@/views/shiftDeliveries/show.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/shift-deliveries/:id/edit',
        name: 'shift-deliveries-update',
        component: () => import(/* webpackChunkName: "shift-deliveries-update" */ '@/views/shiftDeliveries/update.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/shift-deliveries/:id/check-list',
        name: 'shift-deliveries-check-list',
        component: () => import(/* webpackChunkName: "shift-deliveries-create-check-list" */ '@/views/shiftDeliveries/createCheckList.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/shift-deliveries/:id/check-list/edit',
        name: 'shift-deliveries-check-list-edit',
        component: () => import(/* webpackChunkName: "shift-deliveries-create-check-listo-edit" */ '@/views/shiftDeliveries/updateCheckList.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/profiles',
        name: 'admin-profiles-index',
        component: () => import(/* webpackChunkName: "admin-profiles-index" */ '@/views/profiles/index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/files',
        name: 'files-index',
        component: () => import(/* webpackChunkName: "files-index" */ '@/views/files/index.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/files/create',
                name: 'files-create',
                component: () => import(/* webpackChunkName: "files-create" */ '@/views/files/create.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },
]
