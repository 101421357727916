export function setMachineries(state, machineries) {
    state.machineries = machineries;
}

export function SET_MACHINERIES_ALL(state, machineries) {
    state.machineriesAll = machineries;
}

export function setMachinery(state, machinery) {
    state.machinery = machinery;
}

export function SET_DOCUMENTS(state, documents) {
    state.machinery_documents = documents;
}

export function SET_DOCUMENTS_PAGINATION(state, pagination) {
    state.machinery_documents_pagination = pagination;
}

export function SET_MAINTENANCES(state, maintenance) {
    state.machinery_maintenances = maintenance;
}

export function SET_MAINTENANCES_PAGINATION(state, pagination) {
    state.machinery_maintenances_pagination = pagination;
}

export function SET_REPORTS(state, reports) {
    state.machinery_reports = reports;
}

export function SET_REPORTS_PAGINATION(state, pagination) {
    state.machinery_reports_pagination = pagination;
}

