export function SET_CHECK_LISTS(state, check_lists) {
    state.check_lists = check_lists;
}

export function SET_CHECK_LIST(state, check_list) {
    state.check_list = check_list;
}

export function SET_ALL_CHECK_LISTS(state, check_lists) {
    state.check_lists_all = check_lists;
}

