export function setProducts(state, products) {
    state.products = products;
}

export function setProduct(state, product) {
    state.product = product;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}

