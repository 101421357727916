export function SET_MILEAGES(state, mileages) {
    state.mileages = mileages;
}

export function SET_MILEAGE(state, mileage) {
    state.mileage = mileage;
}

export function SET_MILEAGE_FIRSTS(state, firsts) {
    state.mileage_firsts = firsts;
}
export function SET_MILEAGES_PAGINATION(state, pagination) {
    state.pagination = pagination;
}
