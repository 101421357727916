import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getPrehospitalCares(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/prehospital-cares', {
      params: {
        report: data.report
      }
    })
      .then(response => {
        context.commit('SET_PREHOSPITAL_CARES', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function showPrehospitalCares(context, id) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.get('/api/v1/prehospital-cares/' + id)
      .then(response => {
        context.commit('SET_PREHOSPITAL_CARE', response.data.data)
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}


export function storePrehospitalCares(context, data) {
  // console.log(data)
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.post('/api/v1/prehospital-cares', data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function updatePrehospitalCares(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/prehospital-cares/' + data.id, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function deletePrehospitalCares(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.delete('/api/v1/prehospital-cares/' + data)
      .then(response => {
        resolve(response)
      }).catch(err => {
      //console.log(err);
      reject(err)
    })
  })
}


export function signaturePatientPrehospitalCares(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/signature-patient/prehospital-cares/' + data.id, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function signatureWitnessPrehospitalCares(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/signature-witness/prehospital-cares/' + data.id, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}

export function signaturePhysicianPrehospitalCares(context, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/signature-physician/prehospital-cares/' + data.id, data)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}


export function finishPrehospitalCares(context, id) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
    axios.put('/api/v1/finish/prehospital-cares/' + id)
      .then(response => {
        resolve(response)
      }).catch(e => {
      console.log(e.response);
      reject(e)
    })
  })
}
