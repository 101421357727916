export function setLogs(state, logs) {
    state.logs = logs;
}

export function setPagination(state, pagination) {
    state.pagination.last_page = pagination.last_page;
    state.pagination.current_page =pagination.current_page
    state.pagination.from =pagination.from
    state.pagination.per_page =pagination.per_page
    state.pagination.to =pagination.to
    state.pagination.total = pagination.total;

}

export function setResetPagination(state, pagination) {
    state.pagination.current_page = pagination;
}
