export default {
    machineries: [],
    machineriesAll: [],
    machinery: null,
    machinery_documents: [],
    machinery_documents_pagination: {
        total: 0,
        current_page: 0,
    },
    machinery_maintenances: [],
    machinery_maintenances_pagination: {
        total: 0,
        current_page: 0,
    },
    machinery_reports: [],
    machinery_reports_pagination: {
        total: 0,
        current_page: 0,
    },
}
