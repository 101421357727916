export function setRoles(state, roles) {
   state.roles = roles;
}
export function setRol(state, rol) {
    state.rol = rol;
}

export function setPagination(state, pagination) {
    state.pagination = pagination;
}

export function setAbilities(state, abilities) {
    state.abilities = abilities;
}

