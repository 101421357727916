import axios from 'axios'

const auth = JSON.parse(localStorage.getItem('vuex'))

export function getShiftDeliveries(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/shift-deliveries', {
                params: {
                    q: data.search,
                    page: context.state.pagination.current_page
                }
            }
        ).then(response => {
            context.commit('SET_SHIFT_DELIVERIES', response.data.data)
            context.commit('SET_PAGINATION', response.data.meta)
            resolve(response)
        }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function showShiftDeliveries(context, id) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.get('/api/v1/shift-deliveries/' + id)
            .then(response => {
                context.commit('SET_SHIFT_DELIVERY', response.data.data)
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}


export function storeShiftDeliveries(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.post('/api/v1/shift-deliveries', data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function updateShiftDeliveries(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/shift-deliveries/' + data.id, data)
            .then(response => {
                resolve(response)
            }).catch(e => {
            console.log(e.response);
            reject(e)
        })
    })
}

export function deleteShiftDeliveries(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.delete('/api/v1/shift-deliveries/' + data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            reject(err)
        })
    })
}

export function acceptShiftDeliveries(context, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = auth.auth.token_type + ' ' + auth.auth.token
        axios.put('/api/v1/shift-deliveries/accept/' + data)
            .then(response => {
                resolve(response)
            }).catch(err => {
            reject(err)
        })
    })
}
